<template>
    <div id="funding-form">
        <div class="md-layout md-alignment-top-space-between">
            <md-field class="md-layout-item md-size-90">
                <label for="proposedSal">Proposed Salary</label>
                <md-input v-model="proposedSal" required></md-input>
                <span class="md-helper-text">e.g. "$8,000 to $10,000" or "unknown", qualifications or clarifications should be listed in Funding Notes below.</span>
            </md-field>
            <md-field class="md-layout-item md-size-90">
                <label for="fundingSource">Funding Source</label>
                <md-select name="fundingSource" v-model='fundingSource' required>
                    <md-option v-for='(fundingSourceText, fundingSourceKey) in fundingSources' v-bind:key='fundingSourceKey' v-bind:value='fundingSourceKey'>{{ fundingSourceText }}</md-option>
                </md-select>
                <span class="md-helper-text">If funding source does not fit neatly into one of the options select the best match and explain further in Funding Notes below.</span>
            </md-field>

            <!-- Following fields depend on what is selected in fundingSource -->
            <!-- Department -->
            <md-field class="md-layout-item md-size-90" v-if="fundingSource == 'dept'">
                <label for="approvedBudgetAmt">Approved Budget Amount</label>
                <md-input v-model="approvedBudgetAmt" required></md-input>
                <span class="md-helper-text">This is the amount allocated for this position in the annual budgeting process. If this position was not budgeted set this field to 0.</span>
            </md-field>
            <md-field class="md-layout-item md-size-90" v-if="fundingSource == 'dept'">
                <label for="budgetFiscalYear">Budget Fiscal Year</label>
                <md-select name="budgetFiscalYear" v-model='budgetFiscalYear' required>
                    <md-option v-for='year in allYears' v-bind:key='year' v-bind:value='year'>{{ year }}</md-option>
                </md-select>
            </md-field>
            <!-- Endowment, Sponsored, Gift, or Other-Internal -->
            <md-field class="md-layout-item md-size-90" v-if="showBudgetFundCode">
                <label for="budgetFundCode">Budget Fund Code</label>
                <md-input type="number" name="budgetFundCode" v-model="budgetFundCode" required></md-input>
                <span class="md-helper-text">Must be a 6 digit number. If you do not know your budget fund code, please contact your regional business office.</span>
            </md-field>
            <md-field class="md-layout-item md-size-90" v-if="showBudgetFundCode">
                <label for="cref">CREF</label>
                <md-input type="number" name="cref" v-model="cref" required></md-input>
                <span class="md-helper-text">Must be a 4 digit number. If you do not know your CREF code, please contact your regional business office.</span>
            </md-field>
            <!-- End fundingSource conditional section -->

            <md-field class="md-layout-item md-size-90">
                <label for="fundingNotes">Funding Notes</label>
                <md-textarea name="fundingNotes" v-model="fundingNotes"></md-textarea>
            </md-field>
        </div>
        <div class="md-layout md-alignment-top-center">
            <md-button class="md-raised" @click="$emit('setDone', 'fundingStep,PREVIOUS')">Back</md-button>
            <md-button class="md-raised md-primary" @click="validate('NEXT')">Continue</md-button>
            <md-button v-if="returnToConfirm" class="md-raised md-primary" @click="validate('LAST')">Return to Confirmation</md-button>
            <!-- <md-button class="md-raised md-primary" @click="skip">Skip</md-button> -->
        </div>
        <FormInvalidWarning v-bind:showInvalidDialog='showInvalidDialog' v-bind:formErrorMessage='formErrorMessage' v-on:closeWarning='showInvalidDialog = false'/>
    </div>
</template>

<script>
    import FormInvalidWarning from '@/components/FormInvalidWarning.vue'

    export default {
        name: 'Funding',
        components: {
            FormInvalidWarning
        },
        data: function() {
            return {
                proposedSal: this.$store.getters['form/stepData']('fundingStep')['proposedSal'] || "",
                fundingSource: this.$store.getters['form/stepData']('fundingStep')['fundingSource'] || "",
                fundingNotes: this.$store.getters['form/stepData']('fundingStep')['fundingNotes'] || "",
                approvedBudgetAmt: this.$store.getters['form/stepData']('fundingStep')['approvedBudgetAmt'] || "",
                budgetFiscalYear: this.$store.getters['form/stepData']('fundingStep')['budgetFiscalYear'] || "",
                budgetFundCode: this.$store.getters['form/stepData']('fundingStep')['budgetFundCode'] || "",
                cref: this.$store.getters['form/stepData']('fundingStep')['cref'] || "",
                showInvalidDialog: false,
                formErrorMessage: ''
            }
        },
        props: {
            allYears: Array,
            fundingSources: Object,
            returnToConfirm: Boolean
        },
        mounted() {
            this.$root.$on('updateFundingSource', (isLps) => {
                if (isLps) {
                    this.fundingSource = "lps";
                }
            });
        },
        computed: {
            showBudgetFundCode() {
                return this.fundingSource == 'endowment' || this.fundingSource == 'sponsored' || this.fundingSource == 'gift' || this.fundingSource == 'other-internal';
            }
        },
        methods: {
            validate: function (direction) {
                if (!this.proposedSal) {
                    this.formErrorMessage =  "Please provide a proposed salary.";
                    this.showInvalidDialog = true;
                } else if (!this.fundingSource) {
                    this.formErrorMessage =  "Please select a funding source.";
                    this.showInvalidDialog = true;
                }  else if (this.fundingSource) {
                    if (this.fundingSource == 'dept' && !this.approvedBudgetAmt) {
                        this.formErrorMessage =  "Please provide an approved budget amount when using the Department Operating Budget as funding source.";
                        this.showInvalidDialog = true;
                    } else if (this.fundingSource == 'dept' && !this.budgetFiscalYear) {
                        this.formErrorMessage =  "Please select a budget fiscal year when using the Department Operating Budget as funding source.";
                        this.showInvalidDialog = true;
                    } else if (this.showBudgetFundCode) {
                        if (!this.budgetFundCode) {
                            this.formErrorMessage =  "Please provide a budget fund code. Budget fund code must be a 6 digit number only. If you do not know your budget fund code, please contact your regional business office.";
                            this.showInvalidDialog = true;
                        } else if (!this.cref) {
                            this.formErrorMessage =  "Please provide the CREF code. CREF must be a 4 digit number only. If you do not know your CREF code, please contact your regional business office.";
                            this.showInvalidDialog = true;
                        } else if (this.budgetFundCode.length != 6) {
                            this.formErrorMessage =  "Budget fund code must be a 6 digit number";
                            this.showInvalidDialog = true;
                        } else if (this.cref.length != 4) {
                            this.formErrorMessage =  "CREF must be a 4 digit number";
                            this.showInvalidDialog = true;
                        } else {
                            this.saveStep();
                            this.$emit('setDone', 'fundingStep,' + direction);
                        }
                    } else {
                        this.saveStep();
                        this.$emit('setDone', 'fundingStep,' + direction);
                    }
                } else {
                    this.saveStep();
                    this.$emit('setDone', 'fundingStep,' + direction);
                }
            },
            skip: function() {
                this.saveStep();
                this.$emit('setDone', 'fundingStep');
            },
            saveStep: function() {
                // clearing out extra dynamic field data
                if (this.fundingSource != 'dept') {
                    this.approvedBudgetAmt = "";
                    this.budgetFiscalYear = "";
                }
                if (!this.showBudgetFundCode) {
                    this.budgetFundCode = "";
                    this.cref = "";
                }

                this.$store.dispatch('form/updateStep', {
                    step: 'fundingStep',
                    proposedSal: this.proposedSal,
                    fundingSource: this.fundingSource,
                    fundingNotes: this.fundingNotes,
                    approvedBudgetAmt: this.approvedBudgetAmt, // only for dept
                    budgetFiscalYear: this.budgetFiscalYear, // only for dept
                    budgetFundCode: this.budgetFundCode, // only for endowment, gift, sponsored, and other-internal
                    cref: this.cref, // only for endowment, gift, sponsored, and other-internal
                });
            }
        }
    }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    #funding-form {
        text-align: left;
    }
</style>
