<template>
    <div id="confirmation">
        <h1 class="md-title">Please Confirm Case Details:</h1>
        <md-table md-card class="confirmation-table">
            <md-table-toolbar>
                <div class="md-toolbar-section-start">
                </div>
                <h1 class="md-title">Candidate Details:</h1>
                <div class="md-toolbar-section-end">
                    <md-button class="md-icon-button md-primary" @click="$emit('setIncomplete', 'candidateStep')">
                        <md-icon>edit</md-icon>
                        <md-tooltip md-direction="left">Edit this section</md-tooltip>
                    </md-button>
                </div>
            </md-table-toolbar>
            <md-table-row v-if="caseSelected">
                <md-table-cell class="label-cell"><strong>Selected Existing Interfolio Case:</strong></md-table-cell>
                <md-table-cell class="spacer-cell"></md-table-cell>
                <md-table-cell>{{ candidateData.interfolioCase.firstname }} {{ candidateData.interfolioCase.lastname }} -- {{ candidateData.interfolioCase.unit_name }}</md-table-cell>
            </md-table-row>
            <md-table-row v-else-if="candidateData.candidate">
                <md-table-cell class="label-cell"><strong>Selected Penn Person:</strong></md-table-cell>
                <md-table-cell class="spacer-cell"></md-table-cell>
                <md-table-cell>{{ candidateData.candidateFirstName }} {{ candidateData.candidateLastName }}</md-table-cell>
            </md-table-row>
            <md-table-row v-else>
                <md-table-cell class="label-cell"><strong>Creating New Candidate:</strong></md-table-cell>
                <md-table-cell class="spacer-cell"><md-icon>warning</md-icon></md-table-cell>
                <md-table-cell>{{ candidateData.candidateFirstName }} {{ candidateData.candidateLastName }} <span class="md-caption">-- Warning! By selecting this option, you are confirming candidate does not have a Pennkey. <strong>A teaching chronicle will not be generated.</strong></span></md-table-cell>
            </md-table-row>
            <md-table-row>
                <md-table-cell class="label-cell"><strong>Penn ID:</strong></md-table-cell>
                <md-table-cell class="spacer-cell"></md-table-cell>
                <md-table-cell>{{ candidateData.candidatePennId }}</md-table-cell>
            </md-table-row>
            <md-table-row>
                <md-table-cell class="label-cell"><strong>PennKey:</strong></md-table-cell>
                <md-table-cell class="spacer-cell"></md-table-cell>
                <md-table-cell>{{ candidateData.candidatePennkey }}</md-table-cell>
            </md-table-row>
            <md-table-row>
                <md-table-cell class="label-cell"><strong>Email:</strong></md-table-cell>
                <md-table-cell class="spacer-cell"></md-table-cell>
                <md-table-cell>{{ candidateData.candidateEmail }}</md-table-cell>
            </md-table-row>
        </md-table>
        <md-table md-card class="confirmation-table">
            <md-table-toolbar>
                <div class="md-toolbar-section-start">
                </div>
                <h1 class="md-title">Position Details:</h1>
                <div class="md-toolbar-section-end">
                    <md-button class="md-icon-button md-primary" @click="$emit('setIncomplete', 'positionStep')">
                        <md-icon>edit</md-icon>
                        <md-tooltip md-direction="left">Edit this section</md-tooltip>
                    </md-button>
                </div>
            </md-table-toolbar>
            <md-table-row>
                <md-table-cell class="label-cell"><strong>Administrating Org:</strong></md-table-cell>
                <md-table-cell class="spacer-cell"></md-table-cell>
                <md-table-cell>{{ unitName }}</md-table-cell>
            </md-table-row>
            <md-table-row>
                <md-table-cell class="label-cell"><strong>Division:</strong></md-table-cell>
                <md-table-cell class="spacer-cell"></md-table-cell>
                <md-table-cell>{{ positionData.division }}</md-table-cell>
            </md-table-row>
            <md-table-row>
                <md-table-cell class="label-cell"><strong>Appointment Type:</strong></md-table-cell>
                <md-table-cell class="spacer-cell"><md-icon v-if="positionData.overrideReapp">warning</md-icon></md-table-cell>
                <md-table-cell>{{ positionData.caseAction }}<span class="md-caption" v-if="positionData.overrideReapp">--Warning! Our records indicate that this candidate is not eligible for reappointment. You have chosen to override this restriction and will be asked to provide justification.</span></md-table-cell>
            </md-table-row>
            <md-table-row>
                <md-table-cell class="label-cell"><strong>Title:</strong></md-table-cell>
                <md-table-cell class="spacer-cell"></md-table-cell>
                <md-table-cell>{{ positionData.title }}</md-table-cell>
            </md-table-row>
            <md-table-row v-if="positionSpanEntryType == 'term'">
                <md-table-cell class="label-cell"><strong>Start Term:</strong></md-table-cell>
                <md-table-cell class="spacer-cell"></md-table-cell>
                <md-table-cell>{{ allTerms[positionData.startTerm] }}</md-table-cell>
            </md-table-row>
            <md-table-row v-if="positionSpanEntryType == 'term'">
                <md-table-cell class="label-cell"><strong>End Term:</strong></md-table-cell>
                <md-table-cell class="spacer-cell"></md-table-cell>
                <md-table-cell>{{ allTerms[positionData.endTerm] }}</md-table-cell>
            </md-table-row>
            <md-table-row v-if="positionSpanEntryType == 'date'">
                <md-table-cell class="label-cell"><strong>Start Date:</strong></md-table-cell>
                <md-table-cell class="spacer-cell"></md-table-cell>
                <md-table-cell>{{ positionData.startDate }}</md-table-cell>
            </md-table-row>
            <md-table-row v-if="positionSpanEntryType == 'date'">
                <md-table-cell class="label-cell"><strong>End Date</strong></md-table-cell>
                <md-table-cell class="spacer-cell"></md-table-cell>
                <md-table-cell>{{ positionData.endDate }}</md-table-cell>
            </md-table-row>
            <md-table-row>
                <md-table-cell class="label-cell"><strong>Position Description:</strong></md-table-cell>
                <md-table-cell class="spacer-cell"></md-table-cell>
                <md-table-cell>{{ positionData.positionDesc }}</md-table-cell>
            </md-table-row>
            <md-table-row v-if="lpsUser != 'nonLps'">
                <md-table-cell class="label-cell"><strong>LPS Appointment:</strong></md-table-cell>
                <md-table-cell class="spacer-cell"></md-table-cell>
                <md-table-cell>
                    <span v-if="positionData.lps">Yes</span>
                    <span v-else>No</span>
                </md-table-cell>
            </md-table-row>
            <md-table-row>
                <md-table-cell class="label-cell"><strong>Language Instructor Appointment:</strong></md-table-cell>
                <md-table-cell class="spacer-cell"></md-table-cell>
                <md-table-cell>
                    <span v-if="positionData.langInstructor">Yes</span>
                    <span v-else>No</span>
                </md-table-cell>
            </md-table-row>
            <md-table-row v-if="visaInfoAllowed">
                <md-table-cell class="label-cell"><strong>Visa Required:</strong></md-table-cell>
                <md-table-cell class="spacer-cell"></md-table-cell>
                <md-table-cell>
                    <span v-if="positionData.visaRequired">Yes</span>
                    <span v-else>No</span>
                </md-table-cell>
            </md-table-row>
            <md-table-row v-if="visaInfoAllowed">
                <md-table-cell class="label-cell"><strong>Visa Type:</strong></md-table-cell>
                <md-table-cell class="spacer-cell"></md-table-cell>
                <md-table-cell>{{ positionData.visaType }}</md-table-cell>
            </md-table-row>
            <md-table-row v-if="rankStartDateAllowed">
                <md-table-cell class="label-cell"><strong>Rank Start Date:</strong></md-table-cell>
                <md-table-cell class="spacer-cell"></md-table-cell>
                <md-table-cell>{{ positionData.rankStartDate }}</md-table-cell>
            </md-table-row>
            <md-table-row v-if="showReviewCmteSelect">
                <md-table-cell class="label-cell"><strong>Included on Dept. Faculty Review Cmte.</strong></md-table-cell>
                <md-table-cell class="spacer-cell"></md-table-cell>
                <md-table-cell>
                    <span>{{ explodeFacRanks() }}</span>
                </md-table-cell>
            </md-table-row>
            <md-table-row v-if="showDegreeCompleted">
                <md-table-cell class="label-cell"><strong>Ph.D. Completed</strong></md-table-cell>
                <md-table-cell class="spacer-cell"></md-table-cell>
                <md-table-cell>
                    <span v-if="positionData.degreeCompleted">Yes</span>
                    <span v-else>No</span>
                </md-table-cell>
            </md-table-row>
            <md-table-row v-if="showDegreeCompletedDate">
                <md-table-cell class="label-cell"><strong>Expected Degree Completion Date</strong></md-table-cell>
                <md-table-cell class="spacer-cell"></md-table-cell>
                <md-table-cell>{{ positionData.degreeCompletedDate}}</md-table-cell>
            </md-table-row>
            <md-table-row v-if="showCitizenship">
                <md-table-cell class="label-cell"><strong>U.S. Citizenship Status</strong></md-table-cell>
                <md-table-cell class="spacer-cell"></md-table-cell>
                <md-table-cell>{{ positionData.citizenship}}</md-table-cell>
            </md-table-row>
        </md-table>
        <md-table md-card class="confirmation-table" v-if="!hideFunding && positionData.hasFunding">
            <md-table-toolbar>
                <div class="md-toolbar-section-start">
                </div>
                <h1 class="md-title">Salary & Funding Details:</h1>
                <div class="md-toolbar-section-end">
                    <md-button class="md-icon-button md-primary" @click="$emit('setIncomplete', 'fundingStep')">
                        <md-icon>edit</md-icon>
                        <md-tooltip md-direction="left">Edit this section</md-tooltip>
                    </md-button>
                </div>
            </md-table-toolbar>
            <md-table-row>
                <md-table-cell class="label-cell"><strong>Proposed Salary</strong></md-table-cell>
                <md-table-cell class="spacer-cell"></md-table-cell>
                <md-table-cell>{{ fundingData.proposedSal }}</md-table-cell>
            </md-table-row>
            <md-table-row>
                <md-table-cell class="label-cell"><strong>Funding Source:</strong></md-table-cell>
                <md-table-cell class="spacer-cell"></md-table-cell>
                <md-table-cell>{{ fundingSources[fundingData.fundingSource] }}</md-table-cell>
            </md-table-row>
            <md-table-row v-if="fundingData.approvedBudgetAmt">
                <md-table-cell class="label-cell"><strong>Approved Budget Amount:</strong></md-table-cell>
                <md-table-cell class="spacer-cell"></md-table-cell>
                <md-table-cell>{{ fundingData.approvedBudgetAmt }}</md-table-cell>
            </md-table-row>
            <md-table-row v-if="fundingData.budgetFiscalYear">
                <md-table-cell class="label-cell"><strong>Budget Fiscal Year:</strong></md-table-cell>
                <md-table-cell class="spacer-cell"></md-table-cell>
                <md-table-cell>{{ fundingData.budgetFiscalYear }}</md-table-cell>
            </md-table-row>
            <md-table-row v-if="fundingData.budgetFundCode">
                <md-table-cell class="label-cell"><strong>Budget Fund Code:</strong></md-table-cell>
                <md-table-cell class="spacer-cell"></md-table-cell>
                <md-table-cell>{{ fundingData.budgetFundCode }}</md-table-cell>
            </md-table-row>
            <md-table-row v-if="fundingData.cref">
                <md-table-cell class="label-cell"><strong>CREF:</strong></md-table-cell>
                <md-table-cell class="spacer-cell"></md-table-cell>
                <md-table-cell>{{ fundingData.cref }}</md-table-cell>
            </md-table-row>
            <md-table-row>
                <md-table-cell class="label-cell"><strong>Funding Notes:</strong></md-table-cell>
                <md-table-cell class="spacer-cell"></md-table-cell>
                <md-table-cell>{{ fundingData.fundingNotes }}</md-table-cell>
            </md-table-row>
        </md-table>
        <md-table md-card class="confirmation-table" v-if="!hideCourses && positionData.hasCourses">
            <md-table-toolbar>
                <div class="md-toolbar-section-start">
                </div>
                <h1 class="md-title">Course Details:</h1>
                <div class="md-toolbar-section-end">
                    <md-button class="md-icon-button md-primary" @click="$emit('setIncomplete', 'courseStep')">
                        <md-icon>edit</md-icon>
                        <md-tooltip md-direction="left">Edit this section</md-tooltip>
                    </md-button>
                </div>
            </md-table-toolbar>
            <md-table-row>
                <md-table-cell class="label-cell"><strong>Proposed Courses:</strong></md-table-cell>
                <md-table-cell class="spacer-cell"></md-table-cell>
                <md-table-cell>
                    <p v-for="(course, index) in courseData.courses" v-bind:key="index" v-bind:course="course">
                        <span v-if="course.courseNum">
                            {{ course.subjectArea }} {{ course.courseNum }} | {{ allTerms[course.courseTerm] }} | Num. of Sections: {{ course.numSecs }} | Avg. Enrollment: {{ course.avgEnroll }}
                        </span>
                    </p>
                </md-table-cell>
            </md-table-row>
            <md-table-row>
                <md-table-cell class="label-cell"><strong>Proposed Course Notes:</strong></md-table-cell>
                <md-table-cell class="spacer-cell"></md-table-cell>
                <md-table-cell>{{ courseData.allCourseNotes }}</md-table-cell>
            </md-table-row>
        </md-table>
        <div class="confirmation-details">
            <h3>Based on your answers above, you will be required to upload the following to Interfolio:</h3>
            <ul>
                <li v-for='(docInfo, docCode) in documents' v-bind:key="docCode">
                    <strong>{{ docInfo.name }}<span v-if="docInfo.minAllowed != docInfo.maxAllowed"> ({{ docInfo.minAllowed }} to {{ docInfo.maxAllowed }})</span></strong>: {{ docInfo.instructions }}
                </li>
            </ul>
        </div>
        <h3>
            Clicking confirm will kick-off a case in Interfolio based on the information entered above
        </h3>
        <md-button class="md-raised" @click="sendBack">Back</md-button>
        <md-button class="md-raised md-primary" @click="createCase()">Confirm</md-button>
    </div>
</template>

<script>

    export default {
        name: 'Confirmation',
        data: function() {
            return {
            }
        },
        props: {
            allTerms: Object,
            lpsUser: String,
            fundingSources: Object
        },
        computed: {
            formData() {
                return this.$store.getters['form/formData'];
            },
            candidateData() {
                return this.formData['candidateStep'];
            },
            positionData() {
                return this.formData['positionStep'];
            },
            fundingData() {
                return this.formData['fundingStep'];
            },
            courseData() {
                return this.formData['coursesStep'];
            },
            unitName() {
                if (this.positionData.adminOrg){
                    return this.$store.getters['interfolio/unit'](this.positionData.adminOrg).name;
                }
                return "";
            },
            caseSelected() {
                if (this.candidateData.interfolioCase && this.candidateData.interfolioCase.lastname) {
                    return true;
                }
                return false;
            },
            foreignLangInstBoxCheck() {
                if (this.positionData.title) {
                    var flTitleSet = true;
                    if (this.positionData.title.indexOf("Foreign") === -1) {
                        flTitleSet = false;
                    }
                    if (!this.positionData.langInstructor && flTitleSet) {
                        return true;
                    }
                }
                return false;
            },
            foreignLangInstTitleCheck() {
                var flTitleSet = true;
                if (this.positionData.title && this.positionData.title.indexOf("Foreign") === -1) {
                    flTitleSet = false;
                }
                if (this.positionData.langInstructor && !flTitleSet) {
                    return true
                } else {
                    return false;
                }
            },
            documents() {
                if (this.positionData.caseAction && this.positionData.title) {
                    return this.$store.getters['title/documents'](this.positionData.caseAction, this.positionData.title);
                }
                return "";
            },
            titleActions() {
                return this.$store.getters['title/titleActions']
            },
            hideFunding() {
                if (this.positionData.title) {
                    var selectedTitle = this.titleActions.find(item => this.matchFullTitle(item));
                    return selectedTitle.hideFunding;
                }
                return false;
            },
            omitFundingAllowed() {
                if (this.positionData.title) {
                    var selectedTitle = this.titleActions.find(item => this.matchFullTitle(item));
                    return selectedTitle.canOmitFunding;
                }
                return false;
            },
            hideCourses() {
                if (this.positionData.title) {
                    var selectedTitle = this.titleActions.find(item => this.matchFullTitle(item));
                    return selectedTitle.hideCourses;
                }
                return false;
            },
            omitCoursesAllowed() {
                if (this.positionData.title) {
                    var selectedTitle = this.titleActions.find(item => this.matchFullTitle(item));
                    return selectedTitle.canOmitCourses;
                }
                return false;
            },
            visaInfoAllowed() {
                if (this.positionData.title) {
                    var selectedTitle = this.titleActions.find(item => this.matchFullTitle(item));
                    return selectedTitle.canEnterVisaInfo;
                }
                return false;
            },
            rankStartDateAllowed() {
                if (this.positionData.title) {
                    var selectedTitle = this.titleActions.find(item => this.matchFullTitle(item));
                    return selectedTitle.canEnterRankStartDate;
                }
                return false;
            },
            positionSpanEntryType() {
                if (this.positionData.title) {
                    var selectedTitle = this.titleActions.find(item => this.matchFullTitle(item));
                    return selectedTitle.positionSpanEntryType;
                }
                return false;
            },
            showReviewCmteSelect() {
                if (this.positionData.title) {
                    var selectedTitle = this.titleActions.find(item => this.matchFullTitle(item));
                    return selectedTitle.canCreateFacultyCommittee;
                }
                return false;
            },
            showDegreeCompleted() {
                if (this.positionData.title) {
                    var selectedTitle = this.titleActions.find(item => this.matchFullTitle(item));
                    return selectedTitle.canEnterDegreeCompletion;
                }
                return false;
            },
            showDegreeCompletedDate() {
                if (this.positionData.title) {
                    var selectedTitle = this.titleActions.find(item => this.matchFullTitle(item));
                    return selectedTitle.canEnterDegreeCompletion && !this.positionData.degreeCompleted;
                }
                return false;
            },
            showCitizenship() {
                if (this.positionData.title) {
                    var selectedTitle = this.titleActions.find(item => this.matchFullTitle(item));
                    return selectedTitle.canEnterCitizenship;
                }
                return false;
            },
        },
        methods: {
            sendBack() {
                this.$emit('setIncomplete');
            },
            explodeFacRanks(){
                if (this.positionData.facultyCommitteeRankCodes == "") return 'None';
                return this.positionData.facultyCommitteeRankCodes.replace("PROF", "Full Professors").replace("ASST", "Assistant Professors").replace("ASSO", "Associate Professors").replace(",", ", ");
            },
            createCase() {
                let data = this.$store.getters['form/caseKickoffData'];
                this.$store.dispatch('interfolio/kickoffCase', data);
                this.$emit('clearSavedCase');
                this.$router.push('complete');
            },
            matchFullTitle: function(titleItem) {
                if (titleItem.title == this.positionData.title && !titleItem.specialCase) return true;
                if (titleItem.title + " - " + titleItem.specialCase == this.positionData.title) return true;
            }
        }
    }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .md-table-cell {
        text-align: left;
    }
    .green-icon {
        color: green !important;
    }
    .confirmation-details {
        text-align: left;
    }
    .spacer-cell {
        width: 5%;
    }
    .label-cell {
        width: 15%;
    }
</style>
