<template>
    <div class="enrollment-history">
        <md-dialog :md-active.sync="showDialog">
            <md-dialog-title>Enrollment History for {{subject}} {{courseNumber}}</md-dialog-title>

            <md-dialog-content>
                <div v-if="loadStatus === LOAD_STATUS.LOADED">
                    Click on the row to select the term/division which most closely matches the proposed course.
                    <md-table v-model="items" md-sort="term" md-sort-order="desc" md-card @md-selected="onSelect">
                        <md-table-row slot="md-table-row" slot-scope="{item}" md-selectable="single">
                            <md-table-cell md-label="term" >{{item.term}}</md-table-cell>
                            <md-table-cell md-label="avg enrollment" class="avg-enrollment">{{item.averageEnrollment}}</md-table-cell>
                            <md-table-cell md-label="num sections" >{{item.numSections}}</md-table-cell>
                            <md-table-cell md-label="type" >{{item.scheduleType}}</md-table-cell>
                            <md-table-cell md-label="division" >{{item.division}}</md-table-cell>
                        </md-table-row>

                        <md-table-empty-state
                                md-label="No History Found"
                                :md-description="`No history was found for ${subject} ${courseNumber}.`">
                        </md-table-empty-state>

                    </md-table>
                </div>
                <div v-if="loadStatus === LOAD_STATUS.LOADING">
                    Loading Data
                    <md-progress-spinner md-mode="indeterminate"></md-progress-spinner>
                </div>

            </md-dialog-content>

            <md-dialog-actions>
                <md-button class="md-raised md-primary" @click="showDialog = false">Close</md-button>
            </md-dialog-actions>
        </md-dialog>


        <md-button @click="showDialog = true" class="md-icon-button md-primary" :disabled="!courseIsValid" title="View Previous Enrollments">
            <md-icon class="fa fa-list"></md-icon>
            <md-tooltip md-direction="top">Enrollment History</md-tooltip>
        </md-button>
    </div>
</template>

<script>
import {LOAD_STATUS} from "../store/store-utils";

export default {
  name: 'CourseEnrollmentHistory',
  props: ['subject', 'courseNumber', 'courseIndex'],
  created() {
    this.getData();
  },
  watch: {
    subject() {
      this.getData();
    },
    courseNumber() {
      this.getData();
    },
  },
  data: () => ({
    showDialog: false,
    LOAD_STATUS: LOAD_STATUS
  }),
  computed: {
    items: {
      get: function()  {
        return this.$store.getters['course/averageCourseEnrollmentHistory'](this.subject, this.courseNumber).data;
      },
      set: function() {}
    },
    loadStatus: function() {
      return this.$store.getters['course/averageCourseEnrollmentHistory'](this.subject, this.courseNumber).loadStatus;
    },
    courseIsValid: function() {
      if (!this.subject || !this.courseNumber) return false;
      return this.subject.length > 0 && (this.courseNumber.length === 3 || this.courseNumber.length === 4);
    }
  },
  methods: {
    onSelect(term) {
      this.$emit('previousEnrollmentSelected', {courseIndex: parseInt(this.courseIndex), avgEnrollment: term.averageEnrollment} );
      this.showDialog = false;
    },
    getData() {
      if(this.courseIsValid) {
        this.$store.dispatch('course/loadCourseHistory', {subject: this.subject, courseNumber: this.courseNumber});
      }
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    div.enrollment-history {
        display: inline-block;
        left: 87px;
        top: 12px;
    }
    .avg-enrollment {
        font-weight: bold;
        color: #0000FF;
    }
</style>
