<template>
    <div id="course-form">
        <div class="md-layoutmd-alignment-top-center">
            <div class="md-layout-item md-size-100">
                <p>
                    Enter ALL courses that the position will be responsible for teaching during the first year. If exact course number is not known use "000". To remove a course, click the box at the front of a row and click the trash icon.
                </p>
            </div>
        </div>

        <!-- NOTE! It seems that you **must** use md-card for the alternate-header to actually appear -->
        <md-table v-model="courses" md-card @md-selected="selectRows">
            <md-table-toolbar>
                <h1 class="md-title">Proposed Courses</h1>
            </md-table-toolbar>

            <md-table-toolbar slot="md-table-alternate-header" slot-scope="{ count }">
                <div class="md-toolbar-section-start">{{ getDeleteMsg(count) }}</div>
                <div class="md-toolbar-section-end">
                    <md-button class="md-icon-button" @click="removeRows">
                        <md-icon>delete</md-icon>
                    </md-button>
                </div>
            </md-table-toolbar>

            <md-table-row slot="md-table-row" slot-scope="{ item }" md-selectable="multiple">
                <md-table-cell md-label="Subject Area">
                    <md-autocomplete v-model="item.subjectArea" :md-options="subjectAreas" required></md-autocomplete>
                </md-table-cell>
                <md-table-cell md-label="Course Number">
                    <md-field>
                        <md-input v-model='item.courseNum' required></md-input>
                    </md-field>
                </md-table-cell>
                <md-table-cell md-label="Term">
                    <md-field>
                        <md-select v-model='item.courseTerm' required>
                            <md-option v-for='(courseTermText, courseTermKey) in filteredTerms' v-bind:key='courseTermKey' v-bind:value='courseTermKey'>{{ courseTermText }}</md-option>
                        </md-select>
                    </md-field>
                </md-table-cell>
                <md-table-cell md-label="Num. Sections this Term">
                    <md-field>
                        <md-input v-model.number='item.numSecs' type="number" required></md-input>
                    </md-field>
                </md-table-cell>
                <md-table-cell md-label="Avg. Enrollment">
                    <md-field>
                        <md-input v-model='item.avgEnroll'></md-input>
                        <CourseEnrollmentHistory v-bind:subject='item.subjectArea' v-bind:courseNumber='item.courseNum' v-bind:courseIndex='item.row' v-on:previousEnrollmentSelected="previousEnrollmentSelected"/>
                    </md-field>

                </md-table-cell>
            </md-table-row>
        </md-table>
        <md-button class="md-raised md-primary" @click="addRow">Add Another Course</md-button>
        <div class="md-layout md-alignment-top-space-between">
            <md-field class="md-layout-item md-size-60">
                <label for="proposedCourseNotes">Proposed Course Notes</label>
                <md-textarea v-model='allCourseNotes' name="proposedCourseNotes"></md-textarea>
            </md-field>
        </div>
        <md-button class="md-raised" @click="sendBack">Back</md-button>
        <md-button class="md-raised md-primary" @click="validate('NEXT')">Continue</md-button>
        <md-button v-if="returnToConfirm" class="md-raised md-primary" @click="validate('LAST')">Return to Confirmation</md-button>
        <!-- <md-button class="md-raised md-primary" @click="skip">Skip</md-button> -->
        <FormInvalidWarning v-bind:showInvalidDialog='showInvalidDialog' v-bind:formErrorMessage='formErrorMessage' v-on:closeWarning='showInvalidDialog = false'/>
    </div>
</template>

<script>
    import CourseEnrollmentHistory from '@/components/CourseEnrollmentHistory.vue'
    import FormInvalidWarning from '@/components/FormInvalidWarning.vue'

    export default {
        name: 'Course',
        components: {
            CourseEnrollmentHistory,
            FormInvalidWarning
        },
        props: {
            allTerms: Object,
            returnToConfirm: Boolean
        },
        created() {
            this.$store.dispatch('course/loadSubjectAreas');
        },
        mounted() {
            this.$root.$on('updateSubject', () => {
                for (let course of this.courses) {
                    if (course.subjectArea == "") {
                        course.subjectArea = this.defaultSubjectArea;
                    }
                }
            });
            this.$root.$on('updateTerm', () => {
                for (let course of this.courses) {
                    if (course.courseTerm == "") {
                        course.courseTerm = this.defaultTerm;
                    }
                }
            });
        },
        data: function() {
            return {
                allCourseNotes: this.$store.getters['form/stepData']('coursesStep')['allCourseNotes'] || "",
                courses: this.$store.getters['form/stepData']('coursesStep')['courses'] || [{
                    "row": 1,
                    "subjectArea": "",
                    "courseNum": "",
                    "courseTerm": "",
                    "numSecs": "1",
                    "avgEnroll": ""
                }],
                selected: [],
                rowMax: 1,
                showInvalidDialog: false,
                formErrorMessage: ""
            }
        },
        computed: {
            caseLoadStatus() {
                return this.$store.getters['interfolio/caseLoadStatus'];
            },
            subjectAreas() {
                return Object.keys(this.$store.getters['course/subjectAreas']);
            },
            defaultSubjectArea() {
                return this.$store.getters['defaultSubjectArea'];
            },
            defaultTerm() {
                return this.$store.getters['form/stepData']('positionStep')['startTerm'];
            },
            filteredTerms() {
                var startTerm = this.$store.getters['form/stepData']('positionStep')['startTerm'];
                var endTerm = this.$store.getters['form/stepData']('positionStep')['endTerm'];
                var endYears = this.allTerms;
                var termValues = {
                    "_SPRING": {"_SPRING": " Spring", "_SUMMER_11_WEEK": " Summer 11 Week", "_SUMMER_1": " Summer 1", "_SUMMER_2": " Summer 2", "_FALL": " Fall"},
                    "_SUMMER_11_WEEK": {"_SUMMER_11_WEEK": " Summer 11 Week", "_FALL": " Fall"},
                    "_SUMMER_1": {"_SUMMER_1": " Summer 1", "_SUMMER_2": " Summer 2", "_FALL": " Fall"},
                    "_SUMMER_2": {"_SUMMER_2": " Summer 2", "_FALL": " Fall"},
                    "_FALL": {"_FALL": " Fall"}
                };
                if (startTerm) {
                    endYears = {};
                    var current_year = parseInt(new Date().getFullYear());
                    var selectedYear = parseInt(startTerm.slice(0, 4));
                    var selectedTerm = startTerm.slice(4);
                    for (var i = selectedYear; i < current_year + 7; i++) {
                        var endTerms = termValues["_SPRING"];
                        if (i == selectedYear) {
                            endTerms = termValues[selectedTerm];
                        }
                        for(var termKey in endTerms) {
                            endYears[i + termKey] = i + endTerms[termKey];
                            if (endTerm == i + termKey) return endYears;
                        }
                    }
                }
                return endYears;
            },
            prevStep() {
                if (this.hideFunding) {
                    return 'positionStep';
                }
                return 'fundingStep';
            }
        },
        methods: {
            sendBack() {
                this.$emit('setDone', 'courseStep,PREVIOUS');
            },
            selectRows: function(items) {
                this.selected = items;
            },
            removeRows: function() {
                var rows = [];
                for (let i = 0; i < this.selected.length; i++) {
                    rows.push(this.selected[i].row);
                }
                this.courses = this.courses.filter(course => !rows.includes(course.row));
                this.selected = [];
            },
            addRow: function() {
                this.courses.push({
                    "row": this.rowMax + 1,
                    "subjectArea": this.defaultSubjectArea,
                    "courseNum": "",
                    "courseTerm": this.defaultTerm,
                    "numSecs": "1",
                    "avgEnroll": ""
                });
                this.rowMax++;
            },
            getDeleteMsg: function(count) {
                let plural = '';
                if (count > 1) {
                    plural = 's';
                }
                return `Remove ${count} selected course${plural}`;
            },
            previousEnrollmentSelected: function(params) {
                var toUpdate = this.courses.filter(course => course.row == params['courseIndex']);
                toUpdate[0].avgEnroll = params['avgEnrollment'];
            },
            validate: function(direction) {
                for (var i = 0; i < this.courses.length; i++) {
                    if (!this.courses[i].subjectArea) {
                        this.formErrorMessage = "Subject area must be selected for every course.";
                        this.showInvalidDialog = true;
                        return;
                    } else if (!this.courses[i].courseNum) {
                        this.formErrorMessage = "Course number is required for every course.";
                        this.showInvalidDialog = true;
                        return;
                    } else if (!this.courses[i].courseTerm) {
                        this.formErrorMessage = "Term is required for every course.";
                        this.showInvalidDialog = true;
                        return;
                    } else if (!this.courses[i].numSecs) {
                        this.formErrorMessage = "Number of sections is required for every course.";
                        this.showInvalidDialog = true;
                        return;
                    }
                }
                this.$store.dispatch('form/updateStep', {
                    step: 'coursesStep',
                    allCourseNotes: this.allCourseNotes,
                    courses: this.courses,
                });
                this.$emit('setDone', 'courseStep,' + direction);
            },
        }
    }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    #course-form .md-field {
        max-width: 125px;
    }

    .enrollment-history {
        position: absolute;
        left: 125px;
    }

    .md-table-head .md-checkbox.md-theme-default .md-checkbox-container:before {
        content: "Delete All";
        top: 0;
    }

    .md-table-row .md-checkbox.md-theme-default .md-checkbox-container:before {
        content: "Delete";
        top: 0;
    }

</style>
