<template>
    <div class="about">
        <form class="md-layout md-alignment-center-center">
            <md-steppers ref="stepper" class="md-layout-item md-size-75" :md-active-step.sync="active" md-linear md-dynamic-height>
                <md-step id="candidateStep" md-label="Identify Candidate" :md-done.sync="candidateStep.done">
                    <IdentifyCandidate v-on:setDone="setDone" v-bind:presetCase="presetCase" v-bind:initFromStore="initFromStore" v-bind:returnToConfirm="returnToConfirm"/>
                </md-step>
                <md-step id="positionStep" md-label="Position" :md-done.sync="positionStep.done">
                    <GeneralInfo v-on:setDone="setDone" v-bind:allTerms="getTermOptions" v-bind:initFromStore="initFromStore" v-bind:lpsUser="lpsUser"  v-bind:returnToConfirm="returnToConfirm"/>
                </md-step>
                <md-step id="fundingStep" class="skipStep" md-label="Salary and Funding" :md-done.sync="fundingStep.done" :md-editable="fundingStep.stepNeeded">
                    <Funding v-on:setDone="setDone" v-bind:fundingSources="fundingSources" v-bind:initFromStore="initFromStore" v-bind:allYears="getYearOptions(true)"  v-bind:returnToConfirm="returnToConfirm"/>
                </md-step>
                <md-step id="courseStep" md-label="Proposed Courses" :md-done.sync="courseStep.done" :md-editable="courseStep.stepNeeded">
                    <Course v-bind:allTerms="getTermOptions"  v-on:setDone="setDone"  v-bind:initFromStore="initFromStore" v-bind:returnToConfirm="returnToConfirm" />
                </md-step>
                <md-step id="offerLetterStep" md-label="Offer Letter" :md-done.sync="offerLetterStep.done" :md-editable="offerLetterStep.stepNeeded">
                    <OfferLetter v-bind:allTerms="getTermOptions" v-on:setDone="setDone"  v-bind:initFromStore="initFromStore" v-bind:returnToConfirm="returnToConfirm" />
                </md-step>
                <md-step id="confirmationStep" md-label="Confirm" :md-done.sync="confirmationStep.done">
                    <Confirmation v-bind:allTerms="getTermOptions" v-on:clearSavedCase="clearSavedCase" v-bind:fundingSources="fundingSources" v-bind:lpsUser="lpsUser" v-on:setIncomplete="setIncomplete"/>
                </md-step>
            </md-steppers>
        </form>
    </div>
</template>

<script>
    import GeneralInfo from '@/components/GeneralInfo.vue'
    import IdentifyCandidate from '@/components/IdentifyCandidate.vue'
    import Funding from '@/components/Funding.vue'
    import Course from '@/components/Course.vue'
    import Confirmation from '@/components/Confirmation.vue'
    import {FUNDING_SOURCE_TYPES} from "../store/store-utils";
    import OfferLetter from "@/components/OfferLetter.vue";

    export default {
        name: 'home',
        components: {
            OfferLetter,
            GeneralInfo,
            IdentifyCandidate,
            Funding,
            Course,
            Confirmation
        },
        data: () => ({
            active: 'candidateStep',
            candidateStep: {done: false, stepNeeded: true},
            positionStep: {done: false, stepNeeded: true},
            fundingStep: {done: false, stepNeeded: true},
            courseStep: {done: false, stepNeeded: true},
            offerLetterStep: {done: false, stepNeeded: true},
            confirmationStep: {done: false, stepNeeded: true},
            presetCase: 0,
            initFromStore: false,
            returnToConfirm: false
        }),
        created() {
            if(this.$route.query.presetCase) {
                this.presetCase = this.$route.query.presetCase;
            }
            if(this.$route.query.initFromStore) {
              this.initFromStore = true;
            }
            this.$store.dispatch('form/loadForm');
            if (this.$store.getters['form/savePoint'] && this.$store.getters['form/savePoint'] != 'candidateStep') {
                let pages = ['candidateStep', 'positionStep', 'fundingStep', 'courseStep', 'offerLetterStep', 'confirmationStep'];
                let savedPage = this.$store.getters['form/savePoint'];
                for (var p = 1; p < pages.length; p++) {
                    if (savedPage == pages[p]) {
                        this.active = savedPage;
                        break;
                    } else {
                        this[pages[p]].done = true;
                    }
                }
            }
        },
        methods: {
            /**
             * @param params  string in the form  "stepName[,direction]" where direction is 'NEXT,PREVIOUS,LAST'
             */
            setDone: function (params) {
                this.$store.dispatch('form/saveForm');
                this.updateStepsNeeded();
                var paramList = params.split(",");
                var stepName = paramList[0];
                //default direction to NEXT
                var direction = typeof paramList[1] === 'undefined' ? 'NEXT' : paramList[1];
                this[stepName].done = true;
                this.navigateSteps(stepName, direction);
            },
            navigateSteps: function(fromStep, direction) {
                //set default direction as next
                if(typeof direction === 'undefined' || ['NEXT','PREVIOUS','LAST'].indexOf(direction) === -1) {
                    direction = 'NEXT';
                }
                let toStep;
                switch(direction) {
                    case 'PREVIOUS': toStep = this.getPreviousStep(fromStep); break;
                    case 'LAST': toStep = 'confirmationStep'; break;
                    default: toStep = this.getNextStep(fromStep);

                }
                this.active = toStep;
                this.$store.commit('form/setSavePoint', toStep);
                this.$emit('updateNav');
            },
            getPreviousStep(currentStepName) {
                let stepOrder = ['candidateStep', 'positionStep', 'fundingStep', 'courseStep', 'offerLetterStep', 'confirmationStep'];
                let previousStep = '';
                for(let stepName of stepOrder) {
                    if(currentStepName === stepName) return previousStep;
                    if(this[stepName].stepNeeded) {
                        previousStep = stepName;
                    }
                }
            },
            getNextStep(currentStepName) {
                let stepOrder = ['candidateStep', 'positionStep', 'fundingStep', 'courseStep', 'offerLetterStep', 'confirmationStep'];
                let stepFound = false;
                for(let stepName of stepOrder) {
                    if(stepFound && this[stepName].stepNeeded) {
                        return stepName;
                    }
                    if(stepName === currentStepName) stepFound = true;
                }
            },
            updateStepsNeeded() {
                this.candidateStep.stepNeeded = true;
                this.positionStep.stepNeeded = true;
                this.fundingStep.stepNeeded = this.showFunding;
                this.courseStep.stepNeeded = this.showCourses;
                this.offerLetterStep.stepNeeded = this.showOfferLetter;
                this.offerLetterStep.done = this.showOfferLetter;
            },
            setIncomplete: function (step) {
                if(typeof step === 'undefined') {
                    step = this.getPreviousStep('confirmationStep');
                }
                this.active = step;
                this.returnToConfirm = true;
            },
            getYearOptions: function(forBudget = false) {
                var current_year = parseInt(new Date().getFullYear());
                if (forBudget && this.positionData.startTerm) {
                    current_year = parseInt(this.positionData.startTerm.slice(0, 4));
                }
                var allYears = [];
                for (var i = current_year - 1; i < current_year + 7; i++) {
                    if (forBudget) {
                        allYears.push(i + "-" + (i + 1));
                    } else {
                        allYears.push(i);
                    }
                }
                return allYears;
            },
            matchFullTitle: function(titleItem) {
                if (titleItem.title === this.positionData.title && !titleItem.specialCase) return true;
                if (titleItem.title + " - " + titleItem.specialCase === this.positionData.title) return true;
                return false;
            },
            matchFullTitleAction: function(titleActionItem) {
                if(titleActionItem.actionType !== this.positionData.caseAction) return false;
                return this.matchFullTitle(titleActionItem);
            },
            clearSavedCase: function () {
                this.$emit('clearSavedCase');
            }
        },
        computed: {
            getTermOptions: function() {
                var allYears = this.getYearOptions();
                var terms = {
                    "_SPRING": " Spring",
                    "_SUMMER_11_WEEK": " Summer 11 Week",
                    "_SUMMER_1": " Summer 1",
                    "_SUMMER_2": " Summer 2",
                    "_FALL": " Fall"
                };
                var allTerms = {};
                for (var year in allYears) {
                    var yearStr = allYears[year];
                    for(var termKey in terms) {
                        allTerms[yearStr + termKey] = yearStr + terms[termKey];
                    }
                }
                return allTerms;
            },
            lpsUser() {
                return this.$store.getters['user/appointmentPermission'];
            },
            formData() {
                return this.$store.getters['form/formData'];
            },
            positionData() {
                return this.formData['positionStep'];
            },
            titleActions() {
                return this.$store.getters['title/titleActions']
            },
            showFunding() {
                if (this.positionData.title) {
                    var selectedTitle = this.titleActions.find(item => this.matchFullTitleAction(item));
                    if(selectedTitle.hideFunding) return false;
                    if (selectedTitle.canOmitFunding && !this.positionData.hasFunding) {
                        return false;
                    }
                }
                return true;
            },
            showCourses() {
                if (this.positionData.title) {
                    var selectedTitle = this.titleActions.find(item => this.matchFullTitleAction(item));
                    if(selectedTitle.hideCourses) return false;
                    if(selectedTitle.canOmitCourses && !this.positionData.hasCourses) {
                        return false;
                    }
                }
                return true;
            },
            showOfferLetter() {
                if(this.positionData.title) {
                    var selectedTitle = this.titleActions.find(item => this.matchFullTitleAction(item));
                    if(!this?.positionData?.lps  && selectedTitle.offerLetterType !== 'none') {
                        return true;
                    }
                }
                return false;
            },
            fundingSources() {
                var options = {};

                if (this.lpsUser != 'nonLps') {
                    options['lps'] = FUNDING_SOURCE_TYPES.lps;
                }

                if (!this.positionData.lps) {
                    options = {...FUNDING_SOURCE_TYPES};
                    delete(options.lps);
                }
                return options;
            }
        }
    }
</script>

<style>
    .spacer:after {
        height: 0px;
    }
    .spacer {
        min-height: 0px;
    }
    .md-steppers.md-theme-default .md-stepper-header.md-done[disabled='disabled'] .md-button-content,
    .md-steppers.md-theme-default .md-stepper-header.md-done[disabled='disabled'] .md-stepper-number i svg
    {
        text-decoration: line-through;
        color: grey;
    }


    .spacer-no-bottom-margin {
        margin-bottom: 0px;
    }
</style>
