<!-- This reusable validation error modal requires 2 properties to be
set by template: showInvalidDialog (whether to show modal) and
formErrorMessage (text of modal). It will emit 'closeWarning' which
must be handled by parent template. -->

<template>

    <div id="invalid-warning">
        <md-dialog :md-active.sync="showInvalidDialog">
            <md-dialog-title>Please correct the following errors:</md-dialog-title>
            <div class="md-layout md-alignment-top-center">
                <div class="md-layout-item md-size-90">
                    <p style="white-space: pre;">{{ formErrorMessage }}</p>
                </div>
            </div>
            <md-dialog-actions>
                <md-button class="md-raised md-primary" @click="$emit('closeWarning');">Close</md-button>
            </md-dialog-actions>
        </md-dialog>
    </div>

</template>

<script>

    export default {
        name: 'FormInvalidWarning',
        props: {
            showInvalidDialog: Boolean,
            formErrorMessage: String
        }
    }

</script>
