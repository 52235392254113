<template>
    <div class="md-layout" id="offer-letter">
        <div class="md-layout-item md-size-40">
            <div class="md-layout md-alignment-top-space-between">
                <md-checkbox v-model="needsVisa">Candidate Needs Visa</md-checkbox>
                <md-checkbox v-if="showReappointmentPreapproval" v-model="reappointmentDeanApproval">Dean has preapproved consideration for reappointment</md-checkbox>
                <md-field class="md-layout-item md-size-90">
                    <label for="chairName">Chair Name</label>
                    <md-input v-model="chairName" ></md-input>
                    <span class="md-helper-text">the name of the chair/director who is offering the position</span>
                </md-field>
                <md-field class="md-layout-item md-size-90">
                    <label for="chairTitle">Chair Title</label>
                    <md-input v-model="chairTitle" ></md-input>
                    <span class="md-helper-text">title of the chair/director who is offering the position e.g. "Department Chair", "Program Director", "Center Director"</span>
                </md-field>
                <md-field class="md-layout-item md-size-90">
                    <label for="chairTitle">Receive By Date</label>
                    <md-input v-model="receiveByDate" ></md-input>
                    <span class="md-helper-text">date by which signed letter needs to be received</span>
                </md-field>
                <md-field class="md-layout-item md-size-90">
                    <label for="additionalDuties">Additional Duties</label>
                    <md-textarea rows="3" v-model="additionalDuties"></md-textarea>
                    <span class="md-helper-text">provide full sentences e.g.: In addition to these teaching duties, you are expected to do x,y,z</span>
                </md-field>
                <md-field class="md-layout-item md-size-90" v-if="showAdditionalTimeExpectations">
                    <label for="additionalTimeExpectations">Additional Time Expectations</label>
                    <md-textarea rows="3" v-model="additionalTimeExpectations"></md-textarea>
                    <span class="md-helper-text">e.g. required travel time for field work, supervision of other faculty or students etc.</span>
                </md-field>
                <md-field class="md-layout-item md-size-90" v-if="showAdditionalBenefits">
                    <label for="additionalBenefits">Additional Benefits</label>
                    <md-textarea rows="3" v-model="additionalBenefits"></md-textarea>
                    <span class="md-helper-text">use full sentences e.g. "You will be provided with office space"...</span>
                </md-field>
            </div>
            <div class="md-layout md-alignment-top-center">
                <md-button class="md-raised" @click="saveStep('PREVIOUS')">Back</md-button>
                <md-button class="md-raised md-primary" @click="validate('NEXT')">Continue</md-button>
                <md-button v-if="returnToConfirm" class="md-raised md-primary" @click="validate('LAST')">Return to Confirmation</md-button>
                <!-- <md-button class="md-raised md-primary" @click="skip">Skip</md-button> -->
            </div>
            <FormInvalidWarning v-bind:showInvalidDialog='showInvalidDialog' v-bind:formErrorMessage='formErrorMessage' v-on:closeWarning='showInvalidDialog = false'/>
        </div>
        <div id="offer-letter-text" class="md-layout-item md-size-60" >
            <md-button class="md-raised md-primary" @click="copyText()">copy draft offer letter text</md-button>
            <p class="warning">COPY NOW: You only have access to  this offer letter text now and when the case is in your Interfolio queue.</p>
            <textarea ref="offerLetterText" disabled v-model="offerLetterText" style="padding: 5px; width: 100%; height: 60vh"></textarea>
        </div>
    </div>
</template>

<script>
    import FormInvalidWarning from '@/components/FormInvalidWarning.vue'
    import OfferLetter, {OFFER_LETTER_TYPE} from "@/util/OfferLetter";

    export default {
        name: 'OfferLetter',
        components: {
            FormInvalidWarning
        },
        data: function() {
            let stepData = this.$store.getters['form/stepData']('offerLetterStep');
            return {
                chairName: stepData['chairName'] || "",
                chairTitle: stepData['chairTitle'] || "",
                receiveByDate: stepData['receiveByDate'] || "",
                additionalDuties: stepData['additionalDuties'] || "",
                additionalTimeExpectations: stepData['additionalTimeExpectations'] || "",
                additionalBenefits: stepData['additionalBenefits'] || "",
                needsVisa: stepData['needsVisa'] || "",
                reappointmentDeanApproval: stepData['reappointmentDeanApproval'] || false,
                showInvalidDialog: false,
                formErrorMessage: ''
            }
        },
        props: {
            allYears: Array,
            returnToConfirm: Boolean
        },
        mounted() {
            this.$root.$on('updateFundingSource', (isLps) => {
                if (isLps) {
                    this.fundingSource = "lps";
                }
            });
        },
        computed: {
            offerLetterText: {
                get: function() {
                    let offerLetter = new OfferLetter();

                    offerLetter.initializeFormData(this.$store.getters);

                    //set fields from this step
                    offerLetter.chairName = this.chairName;
                    offerLetter.chairTitle = this.chairTitle;
                    offerLetter.receiveByDate = this.receiveByDate;
                    offerLetter.additionalDuties = this.additionalDuties;
                    offerLetter.additionalTimeExpectations = this.additionalTimeExpectations;
                    offerLetter.additionalBenefits = this.additionalBenefits;
                    offerLetter.needsVisa = this.needsVisa;
                    offerLetter.reappointmentDeanApproval = this.reappointmentDeanApproval;

                    return offerLetter.getText();
                }
            },
            offerLetterType() {
                let currentTitleAction = this.$store.getters['title/currentTitleAction']
                if(currentTitleAction) {
                    return currentTitleAction.offerLetterType;
                }
                return OFFER_LETTER_TYPE.NONE;
            },
            showReappointmentPreapproval() {
                return this.offerLetterType === OFFER_LETTER_TYPE.LECTURER_FULL_TIME;
            },
            showAdditionalBenefits() {
                return this.offerLetterType === OFFER_LETTER_TYPE.LECTURER_PART_TIME;
            },
            showAdditionalTimeExpectations() {
                return this.offerLetterType === OFFER_LETTER_TYPE.LECTURER_PART_TIME;
            },
            formData() {
                return this.$store.getters['form/formData'];
            },
            positionData() {
                return this.formData['positionStep'];
            },
            unitName() {
                if (this.positionData.adminOrg){
                    return this.$store.getters['interfolio/unit'](this.positionData.adminOrg).name;
                }
                return "";
            },
        },
        methods: {
            validate: function (direction) {
                if(this.offerLetterText === 'fake error') {
                    this.formErrorMessage =  "Error Message Here";
                    this.showInvalidDialog = true;
                } else {
                    this.saveStep(direction);
                }
            },
            saveStep: function(direction) {
                // clearing out extra dynamic field data
                this.$store.dispatch('form/updateStep', {
                    step: 'offerLetterStep',
                    chairName: this.chairName,
                    chairTitle: this.chairTitle,
                    receiveByDate: this.receiveByDate,
                    additionalDuties: this.additionalDuties,
                    additionalTimeExpectations: this.additionalTimeExpectations,
                    additionalBenefits: this.additionalBenefits,
                    needsVisa: this.needsVisa,
                });
                this.$emit('setDone', 'offerLetterStep,' + direction);
            },
            copyText: function() {
                let text = this.offerLetterText;
                navigator.clipboard.writeText(text).then(
                    function() {
                        alert("Copied the text: \n" + text);
                    }
                )
                .catch(()=> {
                    alert("error in copying text");
                });
            }
        }
    }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    #funding-form {
        text-align: left;
    }
    #offer-letter-text {
    }

    .md-field.md-has-textarea:not(.md-autogrow) .md-textarea[rows="3"] {
        min-height: 75px;
    }
    .warning {
        color: red;
    }
</style>
